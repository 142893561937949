<template>
  <div class="container_wrap">
    <h1>未来网络学堂用户注册使用协议</h1>
    <h2>使用规则</h2>
    <p>用户注册成功后，未来网络学堂将给予每个用户一个用户账号及相应的密码，该用户账号和密码由用户负责保管；用户应当对以其用户账号进行的所有活动和事件负法律责任。</p>
    <p>用户须对在未来网络学堂的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐户导致其他用户误认；否则未来网络学堂有权立即停止提供服务，收回其账号并由用户独自承担由此而产生的一切法律责任。</p>
    <p>用户不得使用未来网络学堂服务发送或传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息：</p>
    <ul>
      <li>反对宪法所确定的基本原则的；</li>
      <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
      <li>损害国家荣誉和利益的；</li>
      <li>煽动民族仇恨、民族歧视，破坏民族团结的；</li>
      <li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
      <li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
      <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
      <li>侮辱或者诽谤他人，侵害他人合法权益的；</li>
      <li>含有法律、行政法规禁止的其他内容的。</li>
      </ul>
      <p>未来网络学堂有权对用户使用未来网络学堂的情况进行审查和监督，如用户在使用未来网络学堂时违反任何上述规定，未来网络学堂或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于删除用户张贴的内容、暂停或终止用户使用未来网络学堂的权利）以减轻用户不当行为造成的影响。</p>
      <p>盗取他人用户账号或利用网络通讯骚扰他人，均属于非法行为。用户不得采用测试、欺骗等任何非法手段，盗取其他用户的账号和对他人进行骚扰。</p>
      <h2>知识产权</h2>
      <p>用户保证和声明对其所提供的作品拥有完整的合法的著作权或完整的合法的授权可以用于其在未来网络学堂上从事的活动，保证未来网络学堂使用该作品不违反国家的法律法规，也不侵犯第三方的合法权益或承担任何义务。用户应对其所提供作品因形式、内容及授权的不完善、不合法所造成的一切后果承担完全责任。</p>
      <p>对于经用户本人创作并上传到未来网络学堂的文本、图片、图形等，未来网络学堂保留对其网站所有内容进行实时监控的权利，并有权依其独立判断对任何违反本协议约定的作品实施删除。未来网络学堂对于删除用户作品引起的任何后果或导致用户的任何损失不负任何责任。</p>
      <p>因用户作品的违法或侵害第三人的合法权益而导致未来网络学堂或其关联公司对第三方承担任何性质的赔偿、补偿或罚款而遭受损失（直接的、间接的、偶然的、惩罚性的和继发的损失），用户对于未来网络学堂或其关联公司蒙受的上述损失承担全面的赔偿责任。</p>
      <h2>免责声明</h2>
      <p>未来网络学堂不能对用户在知识社区回答问题的答案或评论的准确性及合理性进行保证。</p>
      <p>若未来网络学堂已经明示其网络服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。</p>
      <p>用户明确同意其使用未来网络学堂网络服务所存在的风险将完全由其自己承担；因其使用 未来网络学堂服务而产生的一切后果也由其自己承担，未来网络学堂对用户不承担任何责任。</p>
      <p>用户同意保障和维护未来网络学堂及其他用户的利益，用户在未来网络学堂上发表的内容仅表明其个人的立场和观点，并不代表 未来网络学堂 的立场或观点。由于用户发表内容违法、不真实、不正当、侵犯第三方合法权益，或用户违反本协议项下的任何条款而给未来网络学堂或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
      <h2>服务条款的修改</h2>
      <p>未来网络学堂保留在必要时修改服务条款的权利。</p>
      <h2>隐私政策</h2>
      <p>未来网络学堂非常重视对用户隐私权的保护，承诺不会在未获得用户许可的情况下擅自将用户的个人资料信息出租或出售给任何第三方，但以下情况除外：</p>
      <ul>
      <li>您同意让第三方共享资料；</li>
      <li>您同意公开你的个人资料，享受为您提供的产品和服务；</li>
      <li>我们可能会向公司旗下51OpenLab平台同步您的个人信息，以避免重复注册，并且只会获取注册所必需的信息；</li>
      <li>本站需要听从法庭传票、法律命令或遵循法律程序；</li>
      <li>本站发现您违反了本站服务条款或本站其它使用规定。</li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  h1 {
    text-align: center;
    color:#333;
  }
  h2 {
    color:#333;
  }
  p {
    line-height: 2;
    margin-top:0;
    margin-bottom:10px;
    text-indent: 2rem;
  }
  ul {
    list-style: disc;
    padding-left:50px;
    margin-bottom:10px;
    li {
      line-height:2;
    }
  }
</style>
